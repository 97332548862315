import React, { useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { find, kebabCase } from 'lodash'

import { MainContext } from '../context/main'
import { importAll } from '../config/helpers'

import Loading from '../modules/loading'

import './archetypes.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Archetypes = () => {
  const { archetypes, copy, isReady } = useContext( MainContext )
  const history = useHistory()
  const allThumbs = importAll( require.context( '../assets/images/survivor-thumbs', false, /\.(png)$/ ) )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (

    <section className="zc-archetypes zc-section">
      { isReady ?
        (
          <>
            <h2>{ copy[ 'ARCHETYPES_GREETING' ] }</h2>
            <p>{ copy[ 'ARCHETYPES_DESCRIPTION' ] }</p>
            <ul className="zc-archetypes__list">
              { archetypes.data.filter( archetype => archetype.canBeCustomized ).map( ( archetype, index ) => (

                <li key={ index } className="zc-archetypes__item">
                  <figure>
                    <img src={ find( allThumbs, { key: kebabCase( archetype.name ) } )?.module } alt={ archetype.name } />
                  </figure>
                  {/* <h3>{ archetype.name }</h3> */ }
                  <p><strong>Skills:</strong> { archetype.basicSkill }, { archetype.advancedSkill }, { archetype.masterSkill }, { archetype.ultimateSkill }</p>
                  { archetype.archetypeBio.split( '  ' ).map( ( p, index ) => ( <p key={ index }>{ p }</p> ) ) }
                  <Link className="zc-button" to={ `/survivor-identity-sheet/${ kebabCase( archetype.name ) }` }>Select { archetype.name }</Link>
                </li>

              ) ) }
            </ul>
            <button className="zc-button" onClick={ history.goBack }>Back</button>
          </>
        ) : (
          <Loading />
        )
      }
    </section>
  )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default Archetypes