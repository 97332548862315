import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { MainContext } from '../context/main'

import Loading from '../modules/loading'

import './home.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Home = () => {
  const { copy, isReady } = useContext( MainContext )
  const [ started, setStarted ] = useState( false )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <section className="zc-home zc-section">
      { isReady ?
        (
          <>
            <header className="zc-home__header">
              <h2>{ copy[ 'HOME_GREETING' ] }</h2>
              <p>{ copy[ 'HOME_APP_DESCRIPTION' ] }</p>
            </header>

            { started ?
              (

                <div className="zc-home__selector">
                  <h3 className="zc-home__selector-title">{ copy[ 'HOME_START_OPTION_DISCLAIMER' ] }</h3>

                  <div className="zc-home__selector-option">
                    <p>{ copy[ 'HOME_FROM_ARCHETYPE' ] }</p>
                    <Link className="zc-button" to="/archetypes">{ copy[ 'HOME_BTN_FROM_ARCHETYPE' ] }</Link>
                  </div>

                  <div className="zc-home__selector-option">
                    <p>{ copy[ 'HOME_READY_TO_PLAY' ] }</p>
                    <Link className="zc-button" to="/downloads">{ copy[ 'HOME_BTN_READY_TO_PLAY' ] }</Link>
                  </div>

                  <div className="zc-home__selector-option">
                    <p>{ copy[ 'HOME_EDITABLE_SHEETS' ] }</p>
                    <a className="zc-button" href={ `https://zombicide-character-generator.s3.amazonaws.com/sheets/shelter-sheet-v03.pdf` } target="_blank" rel="noreferrer" download>{ copy[ 'HOME_BTN_EDITABLE_SHELTER_SHEET' ] }</a>
                    <a className="zc-button" href={ `https://zombicide-character-generator.s3.amazonaws.com/sheets/survivor-sheet-v03.pdf` } target="_blank" rel="noreferrer" download>{ copy[ 'HOME_BTN_EDITABLE_SURVIVOR_SHEET' ] }</a>
                  </div>
                </div>

              ) : (

                <button className="zc-button" onClick={ evt => setStarted( true ) } >{ copy[ 'HOME_BTN_START' ] }</button>
              )
            }
          </>
        ) : (
          <Loading />
        )
      }
    </section>
  )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default Home