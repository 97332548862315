import { Link } from 'react-router-dom'
import imgLogo from '../assets/images/zombicide-logo.png'

import './header.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Header = () => (
  <header className="zc-header">
    <nav>
      <Link title="Zombicide Chronicles" to="/"><img src={ imgLogo } alt="Zombicide Chronicles" /></Link>
    </nav>
  </header>
)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default Header