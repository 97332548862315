// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/images/bg-header.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zc-footer {\n  width: 100%;\n  display: grid; }\n  .zc-footer__wrapper {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: space-between;\n    justify-self: center;\n    max-width: 100%;\n    align-items: center; }\n  .zc-footer:after {\n    content: '';\n    width: 100%;\n    height: 10px;\n    background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top center;\n    background-size: cover; }\n", "",{"version":3,"sources":["webpack://src/modules/footer.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa,EAAA;EAEb;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,oBAAoB;IACpB,eAAe;IACf,mBAAmB,EAAA;EAVvB;IAcI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,oFAAkF;IAClF,sBAAsB,EAAA","sourcesContent":[".zc-footer {\n  width: 100%;\n  display: grid;\n\n  &__wrapper {\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: space-between;\n    justify-self: center;\n    max-width: 100%;\n    align-items: center;\n  }\n\n  &:after {\n    content: '';\n    width: 100%;\n    height: 10px;\n    background: transparent url('../assets/images/bg-header.png') no-repeat top center;\n    background-size: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
