import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { MainState } from './context/main'

import Home from './pages/home'
import Archetypes from './pages/archetypes'
import Downloads from './pages/downloads'
import SurvivorIdentitySheet from './pages/survivor-identity-sheet'
import Header from './modules/header'
import Footer from './modules/footer'

import reportWebVitals from './reportWebVitals'

import './index.scss'

ReactDOM.render(
  <React.StrictMode>
    <MainState>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/survivor-identity-sheet"><SurvivorIdentitySheet /></Route>
          <Route path="/survivor-identity-sheet/:archetypeId"><SurvivorIdentitySheet /></Route>
          <Route path="/archetypes"><Archetypes /></Route>
          <Route path="/downloads"><Downloads /></Route>
          <Route path="/"><Home /></Route>
        </Switch>
        <Footer />
      </Router>
    </MainState>
  </React.StrictMode>,
  document.getElementById( 'root' )
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
