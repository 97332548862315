import { slice } from 'lodash'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const fetcherTemplate = {
  isFetching: false,
  isFetched: false,
  data: null,
  error: null
}

export const fetcher = async ( stateFn, endpoint, initData = null ) => {
  try {

    stateFn( { ...fetcherTemplate, isFetching: true, data: initData } )
    const data = await loadTsvAsJson( endpoint )
    stateFn( { ...fetcherTemplate, isFetched: true, data: data } )

  } catch ( exception ) {

    stateFn( { ...fetcherTemplate, data: initData, error: exception } )

  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const loadTsvAsJson = async url => {

  const getValue = v => {
    if ( v === 'TRUE' ) return true
    if ( v === 'FALSE' ) return false
    if ( !isNaN( parseInt( v ) ) ) return parseInt( v )
    return v.toString().trim()
  }

  const req = await fetch( url )
  const text = await req.text()
  const arrParse = text.split( /\n/ ).map( item => item.split( /\t/ ) )
  const keys = arrParse[ 0 ]

  return slice( arrParse, 1 ).map( item => {
    const node = {}

    for ( let i in keys ) {
      const v = item[ i ]
      node[ keys[ i ].replace( /\s/g, '' ) ] = getValue( v )
    }

    return node
  } )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const importAll = rCtx => rCtx.keys().map( key => ( {
  key: key.substr( 0, key.lastIndexOf( '.' ) ).substr( 2 ),
  module: rCtx( key ).default
} ) )

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -