import ReactTooltip from 'react-tooltip'

const Tooltip = ( { id, content = '' } ) => {
  return (
    <>
      <span className="zc-tooltip__trigger" data-tip data-for={ id }>?</span>
      <ReactTooltip id={ id } aria-haspopup="true" place="right" >
        <div className="zc-tooltip__wrapper">
          { content.split( "\\n" ).map( ( p, index ) => ( <p key={ index }>{ p }</p> ) ) }
        </div>
      </ReactTooltip>
    </>
  )
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default Tooltip