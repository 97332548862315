import React, { useContext } from 'react'

import { MainContext } from '../context/main'

import './footer.scss'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Footer = () => {
  const { isReady, copy } = useContext( MainContext )

  return isReady ? (
    <footer className="zc-footer">
      <div className="zc-footer__wrapper zc-section">
        <span>{ copy[ 'FOOTER_COPYRIGHT' ].replace( '#year', new Date().getFullYear() ) }</span>
        <a href="https://zombicide-character-generator.s3.amazonaws.com/other/RPZ-FAQ-Errata.pdf" className="zc-button-sm" target="_blank" rel="noreferrer" download>{ copy[ 'FOOTER_BTN_FAQ' ] }</a>
      </div>
    </footer>
  ) : null
}




// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default Footer