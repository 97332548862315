import { ReactComponent as SVGLoading } from '../assets/images/loading.svg'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const Loading = () => (
  <div className="zc-loading">
    <SVGLoading />
  </div>
)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default Loading