// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/images/bg-header.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zc-header {\n  display: grid;\n  justify-content: center;\n  padding: 20px;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top center;\n  background-size: contain; }\n  .zc-header img {\n    border: 0;\n    max-width: 100%; }\n", "",{"version":3,"sources":["webpack://src/modules/header.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,oFAAkF;EAClF,wBAAwB,EAAA;EAL1B;IAQI,SAAS;IACT,eAAe,EAAA","sourcesContent":[".zc-header {\n  display: grid;\n  justify-content: center;\n  padding: 20px;\n  background: transparent url('../assets/images/bg-header.png') no-repeat top center;\n  background-size: contain;\n\n  img {\n    border: 0;\n    max-width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
