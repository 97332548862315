import React, { useState, useEffect, useCallback } from 'react'
import { forEach, kebabCase, find, groupBy } from 'lodash'

import { fetcher, fetcherTemplate } from '../config/helpers'

export const MainContext = React.createContext()

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const MainState = props => {
  const [isReady, setReady] = useState(false)
  const [copy, setCopy] = useState({ ...fetcherTemplate })
  const [archetypes, setArchetypes] = useState({ ...fetcherTemplate })
  const [skills, setSkills] = useState({ ...fetcherTemplate })
  const [actions, setActions] = useState({ ...fetcherTemplate })
  const [attributes, setAttributes] = useState({ ...fetcherTemplate })
  const [proficiencies, setProficiencies] = useState({ ...fetcherTemplate })

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => {
    fetcher(setCopy, process.env.REACT_APP_COPY_TSV_URL)
    fetcher(setArchetypes, process.env.REACT_APP_ARCHETYPES_TSV_URL)
    fetcher(setSkills, process.env.REACT_APP_SKILLS_TSV_URL)
    fetcher(setActions, process.env.REACT_APP_ACTIONS_TSV_URL)
    fetcher(setAttributes, process.env.REACT_APP_ATTRIBUTES_TSV_URL)
    fetcher(setProficiencies, process.env.REACT_APP_PROFICIENCIES_TSV_URL)
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const getArchetypeById = useCallback(key => find(archetypes.data, item => kebabCase(item.name) === key) || null, [archetypes.data])

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const parseCopy = () => {
    if (copy.isFetched && !copy.isParsed) {
      const dict = {}
      forEach(copy.data, item => dict[item.key] = item['en_US'])
      setCopy({
        ...copy,
        isParsed: true,
        data: dict
      })
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const parseSkills = () => {
    if (skills.isFetched && !skills.isParsed) {
      setSkills({
        ...skills,
        isParsed: true,
        data: groupBy(skills.data, 'level')
      })
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleContentReady = () => {
    if (
      archetypes.isFetched &&
      skills.isFetched &&
      actions.isFetched &&
      attributes.isFetched &&
      proficiencies.isFetched &&
      copy.isFetched &&
      copy.isParsed
    ) setReady(true)
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect(initialize, [])
  useEffect(parseCopy, [copy])
  useEffect(parseSkills, [skills])
  useEffect(handleContentReady, [copy, archetypes, skills, actions, attributes, proficiencies])

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <MainContext.Provider value={{

      isReady: isReady,
      copy: copy.data,
      archetypes: archetypes,
      skills: skills,
      actions: actions,
      attributes: attributes,
      proficiencies: proficiencies,
      getArchetypeById: getArchetypeById

    }}>{props.children}</MainContext.Provider>
  )
}