import React, { Fragment, useContext, useEffect, useState } from 'react'
import { capitalize, values, uniq, indexOf, groupBy, compact, kebabCase } from 'lodash'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { forEach } from 'lodash'
import jsPDF from 'jspdf'
// import PDFObject from 'pdfobject'

import './survivor-identity-sheet.scss'
import '../assets/fonts/GorriSans-normal'

import imgSheet from '../assets/images/empty-sheet.jpg'
import imgStroke from '../assets/images/stroke.png'

import { MainContext } from '../context/main'

import Tooltip from '../modules/tooltip'
import Loading from '../modules/loading'

const SurvivorIdentitySheet = () => {
  const { archetypeId } = useParams()
  const { getArchetypeById, copy, isReady, skills, actions, proficiencies, attributes } = useContext( MainContext )
  const [ archetype, setArchetype ] = useState( null )
  const [ canRender, setCanRender ] = useState( !archetypeId )
  const [ isProcessing, setProcessing ] = useState( false )
  const [ sheet, setSheet ] = useState( {
    archetypeBased: false,
    name: '',
    occupation: '',
    habit: '',
    looks: '',
    basicSkill: '',
    advancedSkill: '',
    masterSkill: '',
    ultimateSkill: '',
    attrMuscle: '',
    attrBrains: '',
    attrGrit: '',
    attributes: '',
    proficiencies: '',
    actions: []
  } )
  const { register, handleSubmit, watch, errors, setError, clearErrors } = useForm()
  const allFields = watch()
  const pdf = new jsPDF()

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const initialize = () => { }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const normalizeItemLength = ( item, maxLength ) => (
    item.length > maxLength ? `${ item.substr( 0, ( maxLength - 3 ) ) }...` : item
  )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const delay = async duration => new Promise( resolve => {
    setTimeout( () => { resolve() }, duration )
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const renderPDF = async payload => new Promise( async resolve => {
    await delay( 1000 )

    pdf.setFont( 'GorriSans' )
    pdf.setFontSize( 8 )
    pdf.addImage( imgSheet, 'JPEG', 0, 0, 210, 297, 'sheet', 'FAST', 0 )
    pdf.text( normalizeItemLength( payload.basicSkill, 22 ), 36, 47 )
    pdf.text( normalizeItemLength( payload.advancedSkill, 22 ), 77, 47 )
    pdf.text( normalizeItemLength( payload.masterSkill, 22 ), 120, 47 )
    pdf.text( normalizeItemLength( payload.ultimateSkill, 22 ), 161, 47 )
    pdf.setFontSize( 10 )
    pdf.text( normalizeItemLength( payload.name, 19 ), 47, 72 )
    pdf.text( normalizeItemLength( payload.occupation, 29 ), 46, 81 )
    pdf.text( normalizeItemLength( payload.habit, 33 ), 36, 94 )
    pdf.text( normalizeItemLength( payload.looks, 33 ), 37, 107 )

    forEach( payload.action, ( item ) => {
      item = item.split( ',' )
      pdf.addImage( imgStroke, 'PNG', parseInt( item[ 0 ] ), parseInt( item[ 1 ] ), 17, 2.4, 'pos-1-1', 'FAST', 0 )
    } )

    pdf.setFontSize( 13 )
    pdf.text( payload.attributes.MUSCLE.toString(), 57, 127 )
    pdf.text( payload.attributes.BRAINS.toString(), 77, 127 )
    pdf.text( payload.attributes.GRIT.toString(), 97, 127 )

    pdf.text( payload.proficiencies.ATHLETICS.toString(), 43, 136 )
    pdf.text( payload.proficiencies.ATTITUDE.toString(), 43, 147 )
    pdf.text( payload.proficiencies.BACKGROUND.toString(), 43, 157 )
    pdf.text( payload.proficiencies.COMBAT.toString(), 43, 167 )
    pdf.text( payload.proficiencies.PERCEPTION.toString(), 43, 178 )
    pdf.text( payload.proficiencies.SURVIVAL.toString(), 43, 188 )

    pdf.setFontSize( 21 )
    pdf.text( ( ( payload.attributes.BRAINS + payload.attributes.GRIT ) * 2 ).toString(), 23, 215, null, 7 )
    pdf.text( ( payload.attributes.MUSCLE + payload.attributes.GRIT ).toString(), 23, 249, null, -13 )

    // PDFObject.embed( pdf.output( 'bloburl', 'cool-name.pdf' ), '#pdf-output' )
    pdf.save( `${ kebabCase( payload.name ) }.pdf` )

    resolve()
  } )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleArchetypeId = () => {
    if ( isReady && archetypeId ) {
      const current = getArchetypeById( archetypeId )

      if ( current ) {
        console.log( current )
        setArchetype( current )
        setSheet( {
          ...current,
          archetypeBased: true
        } )
        setCanRender( true )
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleAttributesRules = ( attribute, value ) => {
    let isValid = true

    const attributes = {
      ...allFields.attributes,
      [ attribute ]: value
    }

    const attrValues = values( attributes )

    if ( attrValues.length === 3 && indexOf( attrValues, 0 ) < 0 && uniq( attrValues ).length !== 3 ) {
      setError( 'attributesRules', { message: copy[ 'SHEET_ATTRIBUTES_RULES_REQUIRED' ] } )
      isValid = false
    }

    if ( isValid ) clearErrors( [ 'attributesRules' ] )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleProficienciesRules = ( proficiency, value ) => {
    let isValid = true

    const proficiencies = {
      ...allFields.proficiencies,
      [ proficiency ]: value
    }

    const profValues = compact( values( proficiencies ) )
    const profGroupedValues = groupBy( profValues )
    if ( profValues.length === 6 && ( profGroupedValues[ '1' ]?.length !== 1 || profGroupedValues[ '2' ]?.length !== 3 || profGroupedValues[ '3' ]?.length !== 2 ) ) {
      setError( 'proficienciesRules', { message: copy[ 'SHEET_PROFICIENCIES_RULES_REQUIRED' ] } )
      isValid = false
    }

    if ( isValid ) clearErrors( [ 'proficienciesRules' ] )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleFormSubmit = async payload => {
    setProcessing( true )
    await renderPDF( payload )
    setProcessing( false )
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect( initialize, [] )
  useEffect( handleArchetypeId, [ archetypeId, getArchetypeById, isReady ] )

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <section className="zc-survivor-identity-sheet zc-section">

      { isReady && canRender ? (

        <>

          <form className="zc-survivor-identity-sheet__form" onSubmit={ handleSubmit( handleFormSubmit ) }>
            <p className="zc-survivor-identity-sheet__greeting">{ copy[ 'SHEET_GREETING' ] }</p>

            <div className="zc-survivor-identity-sheet__group zc-survivor-identity-sheet__group--2-col">

              <h3>Bio</h3>

              <div className="zc-survivor-identity-sheet__field">
                <label>{ copy[ 'SHEET_FIELD_NAME_LABEL' ] }</label>
                <div className="zc-survivor-identity-sheet__field-wrapper">
                  <input type="text" maxLength="20" defaultValue={ sheet.name } name="name" ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) } />
                  <Tooltip id={ copy[ 'SHEET_FIELD_NAME_LABEL' ] } content={ copy[ 'SHEET_FIELD_NAME_TOOLTIP' ] } />
                </div>
                { errors.name && <span className="zc-survivor-identity-sheet__field-error">{ errors.name.message }</span> }
              </div>

              <div className="zc-survivor-identity-sheet__field">
                <label>{ copy[ 'SHEET_FIELD_OCCUPATION_LABEL' ] }</label>
                <div className="zc-survivor-identity-sheet__field-wrapper">
                  <input type="text" maxLength="20" defaultValue={ sheet.occupation } name="occupation" ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) } />
                  <Tooltip id={ copy[ 'SHEET_FIELD_OCCUPATION_LABEL' ] } content={ copy[ 'SHEET_FIELD_OCCUPATION_TOOLTIP' ] } />
                </div>
                { errors.occupation && <span className="zc-survivor-identity-sheet__field-error">{ errors.occupation.message }</span> }
              </div>

              <div className="zc-survivor-identity-sheet__field">
                <label>{ copy[ 'SHEET_FIELD_HABIT_LABEL' ] }</label>
                <div className="zc-survivor-identity-sheet__field-wrapper">
                  <input type="text" maxLength="20" defaultValue={ sheet.habit } name="habit" ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) } />
                  <Tooltip id={ copy[ 'SHEET_FIELD_HABIT_LABEL' ] } content={ copy[ 'SHEET_FIELD_HABIT_TOOLTIP' ] } />
                </div>
                { errors.habit && <span className="zc-survivor-identity-sheet__field-error">{ errors.habit.message }</span> }
              </div>

              <div className="zc-survivor-identity-sheet__field">
                <label>{ copy[ 'SHEET_FIELD_LOOKS_LABEL' ] }</label>
                <div className="zc-survivor-identity-sheet__field-wrapper">
                  <input type="text" maxLength="20" defaultValue={ sheet.looks } name="looks" ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) } />
                  <Tooltip id={ copy[ 'SHEET_FIELD_LOOKS_LABEL' ] } content={ copy[ 'SHEET_FIELD_LOOKS_TOOLTIP' ] } />
                </div>
                { errors.looks && <span className="zc-survivor-identity-sheet__field-error">{ errors.looks.message }</span> }
              </div>

            </div>

            <hr className="zc-survivor-identity-sheet__separator" />

            { sheet.archetypeBased ?
              (
                <>

                  <input type="hidden" name="basicSkill" ref={ register() } defaultValue={ sheet.basicSkill } />
                  <input type="hidden" name="advancedSkill" ref={ register() } defaultValue={ sheet.advancedSkill } />
                  <input type="hidden" name="masterSkill" ref={ register() } defaultValue={ sheet.masterSkill } />
                  <input type="hidden" name="ultimateSkill" ref={ register() } defaultValue={ sheet.ultimateSkill } />

                </>
              ) : (
                <>

                  <div className="zc-survivor-identity-sheet__group zc-survivor-identity-sheet__group--2-col">

                    <h3>Skills</h3>

                    <div className="zc-survivor-identity-sheet__field">
                      <label>{ copy[ 'SHEET_FIELD_SKILL1_LABEL' ] }</label>
                      <div className="zc-survivor-identity-sheet__field-wrapper">
                        <select name="basicSkill" ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) } defaultValue={ sheet.basicSkill }>
                          <option value="">Please select</option>
                          { skills.data.BASIC.map( ( item, index ) => (
                            <option key={ index } value={ item.skill }>{ item.skill }</option>
                          ) ) }
                        </select>
                        <Tooltip id={ copy[ 'SHEET_FIELD_SKILL1_LABEL' ] } content={ copy[ 'SHEET_FIELD_SKILL1_TOOLTIP' ] } />
                      </div>
                      { errors.occupation && <span className="zc-survivor-identity-sheet__field-error">{ errors.basicSkill.message }</span> }
                    </div>

                    <div className="zc-survivor-identity-sheet__field">
                      <label>{ copy[ 'SHEET_FIELD_SKILL2_LABEL' ] }</label>
                      <div className="zc-survivor-identity-sheet__field-wrapper">
                        <select name="advancedSkill" ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) } defaultValue={ sheet.advancedSkill }>
                          <option value="">Please select</option>
                          <optgroup label="Advanced">
                            { skills.data.ADVANCED.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                          <optgroup label="Basic">
                            { skills.data.BASIC.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                        </select>
                        <Tooltip id={ copy[ 'SHEET_FIELD_SKILL2_LABEL' ] } content={ copy[ 'SHEET_FIELD_SKILL2_TOOLTIP' ] } />
                      </div>
                      { errors.occupation && <span className="zc-survivor-identity-sheet__field-error">{ errors.advancedSkill.message }</span> }
                    </div>

                    <div className="zc-survivor-identity-sheet__field">
                      <label>{ copy[ 'SHEET_FIELD_SKILL3_LABEL' ] }</label>
                      <div className="zc-survivor-identity-sheet__field-wrapper">
                        <select name="masterSkill" ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) } defaultValue={ sheet.masterSkill }>
                          <option value="">Please select</option>
                          <optgroup label="Master">
                            { skills.data.MASTER.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                          <optgroup label="Advanced">
                            { skills.data.ADVANCED.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                          <optgroup label="Basic">
                            { skills.data.BASIC.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                        </select>
                        <Tooltip id={ copy[ 'SHEET_FIELD_SKILL3_LABEL' ] } content={ copy[ 'SHEET_FIELD_SKILL3_TOOLTIP' ] } />
                      </div>
                      { errors.occupation && <span className="zc-survivor-identity-sheet__field-error">{ errors.masterSkill.message }</span> }
                    </div>

                    <div className="zc-survivor-identity-sheet__field">
                      <label>{ copy[ 'SHEET_FIELD_SKILL4_LABEL' ] }</label>
                      <div className="zc-survivor-identity-sheet__field-wrapper">
                        <select name="ultimateSkill" ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) } defaultValue={ sheet.ultimateSkill }>
                          <option value="">Please select</option>
                          <optgroup label="Ultimate">
                            { skills.data.ULTIMATE.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                          <optgroup label="Master">
                            { skills.data.MASTER.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                          <optgroup label="Advanced">
                            { skills.data.ADVANCED.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                          <optgroup label="Basic">
                            { skills.data.BASIC.map( ( item, index ) => (
                              <option key={ index } value={ item.skill }>{ item.skill }</option>
                            ) ) }
                          </optgroup>
                        </select>
                        <Tooltip id={ copy[ 'SHEET_FIELD_SKILL4_LABEL' ] } content={ copy[ 'SHEET_FIELD_SKILL4_TOOLTIP' ] } />
                      </div>
                      { errors.occupation && <span className="zc-survivor-identity-sheet__field-error">{ errors.ultimateSkill.message }</span> }
                    </div>

                  </div>

                  <hr className="zc-survivor-identity-sheet__separator" />

                </>
              )
            }

            <div className="zc-survivor-identity-sheet__group">

              <h3>Actions <Tooltip id="actions" content={ copy[ 'SHEET_FIELD_ACTIONS_TOOLTIP' ] } /></h3>

              <ul className="zc-survivor-identity-sheet__actions">
                { actions.data.map( ( item, index ) => {

                  const isElegible = item.attribute === archetype?.attribute || item.proficiency === archetype?.proficiency

                  return (

                    <li key={ index } className={ `zc-survivor-identity-sheet__action ${ !isElegible ? 'zc-survivor-identity-sheet__action--disabled' : '' }` }>
                      { isElegible ? (
                        <input
                          disabled={ allFields.action?.length >= 4 && allFields.action?.indexOf( `${ item.xPos },${ item.yPos }` ) < 0 }
                          type="checkbox"
                          name="action"
                          id={ item.action }
                          value={ `${ item.xPos },${ item.yPos }` }
                          ref={ register( { required: copy[ 'SHEET_FIELD_REQUIRED' ] } ) }
                        />
                      ) : <span>-</span> }
                      <label htmlFor={ item.action }>{ item.action }</label>
                    </li>

                  )
                } ) }
              </ul>

              { errors.action && <span className="zc-survivor-identity-sheet__field-error">{ errors.action.message }</span> }

            </div>

            <hr className="zc-survivor-identity-sheet__separator" />

            <div className="zc-survivor-identity-sheet__group zc-survivor-identity-sheet__group--3-col">

              <h3>Attributes <Tooltip id="attributes" content={ copy[ 'SHEET_ATTRIBUTES_TOOLTIP' ] } /></h3>

              { attributes.data.map( ( item, index ) => (

                <div key={ item.attribute } className="zc-survivor-identity-sheet__field">
                  <label>{ capitalize( item.attribute ) }</label>
                  <div className="zc-survivor-identity-sheet__field-wrapper">
                    { sheet.archetypeBased && ( archetype.attribute === item.attribute ) && false ?
                      (

                        <>
                          <input
                            type="hidden"
                            name={ `attributes[${ item.attribute }]` }
                            defaultValue="3"
                            ref={
                              register( {
                                required: copy[ 'SHEET_FIELD_REQUIRED' ],
                                validate: handleAttributesRules.bind( this, item.attribute ),
                                valueAsNumber: true
                              } )
                            }
                          />
                          <span>3</span>
                        </>

                      ) : (

                        <>
                          <select
                            name={ `attributes[${ item.attribute }]` }
                            ref={
                              register( {
                                required: copy[ 'SHEET_FIELD_REQUIRED' ],
                                validate: handleAttributesRules.bind( this, item.attribute ),
                                valueAsNumber: true
                              } )
                            }>
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </>

                      )
                    }
                    <Tooltip id={ item.attribute } content={ copy[ `SHEET_FIELD_${ item.attribute }_TOOLTIP` ] } />
                  </div>
                  { errors.attributes && errors.attributes[ item.attribute ] && <span className="zc-survivor-identity-sheet__field-error">{ errors.attributes[ item.attribute ].message }</span> }
                </div>

              ) ) }
              { errors.attributesRules && <span className="zc-survivor-identity-sheet__field-error">{ errors.attributesRules.message } <br /></span> }

            </div>

            <hr className="zc-survivor-identity-sheet__separator" />

            <div className="zc-survivor-identity-sheet__group zc-survivor-identity-sheet__group--3-col">

              <h3>Proficiencies <Tooltip id="proficiencies" content={ copy[ 'SHEET_PROFICIENCIES_TOOLTIP' ] } /></h3>

              { proficiencies.data.map( ( item, index ) => (

                <div key={ item.proficiency } className="zc-survivor-identity-sheet__field">
                  <label>{ capitalize( item.proficiency ) }</label>
                  <div className="zc-survivor-identity-sheet__field-wrapper">
                    { sheet.archetypeBased && ( archetype.proficiency === item.proficiency ) && false ?
                      (

                        <>
                          <input
                            type="hidden"
                            name={ `proficiencies[${ item.proficiency }]` }
                            defaultValue="3"
                            ref={
                              register( {
                                required: copy[ 'SHEET_FIELD_REQUIRED' ],
                                validate: handleProficienciesRules.bind( this, item.proficiency ),
                                valueAsNumber: true
                              } )
                            }
                          />
                          <span>3</span>
                        </>

                      ) : (

                        <>
                          <select
                            name={ `proficiencies[${ item.proficiency }]` }
                            ref={
                              register( {
                                required: copy[ 'SHEET_FIELD_REQUIRED' ],
                                validate: handleProficienciesRules.bind( this, item.proficiency ),
                                valueAsNumber: true
                              } )
                            }>
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </>

                      )
                    }
                    <Tooltip id={ item.proficiency } content={ copy[ `SHEET_FIELD_${ item.proficiency }_TOOLTIP` ] } />
                  </div>
                  { errors.proficiencies && errors.proficiencies[ item.proficiency ] && <span className="zc-survivor-identity-sheet__field-error">{ errors.proficiencies[ item.proficiency ].message }</span> }
                </div>

              ) ) }
              { errors.proficienciesRules && <span className="zc-survivor-identity-sheet__field-error">{ errors.proficienciesRules.message } <br /></span> }

            </div>

            <hr className="zc-survivor-identity-sheet__separator" />

            <button className="zc-button" type="submit" disabled={ isProcessing }>{ isProcessing ? copy[ 'SHEET_BTN_DOWNLOAD_PROCESSING' ] : copy[ 'SHEET_BTN_DOWNLOAD' ] }</button>

          </form>

          {/* <div id="pdf-output"></div> */ }
        </>

      ) : (
        <Loading />
      )
      }

    </section>
  )
}

export default SurvivorIdentitySheet